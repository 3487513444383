const initialState = {
  videos: [],
  productDetail: {},
  params: {},
  totalVideos: 0,
  totalHours: 0,
  totalMins: 0,
  brands: [],
  cats: []
}

const videoLibrary = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VIDEOS':
      return { 
        ...state, 
        videos: action.data.rows, 
        params: action.params, 
        totalVideos: action.data.total, 
        totalHours: action.data.totalHours, 
        totalMins: action.data.totalMins, 
        brands: action.data.brands, 
        cats: action.data.categories
      }
    case 'GET_VIDEO':
      return { ...state, data: action.data, params: action.params }
    default:
      return state
  }
}

export default videoLibrary
